<template>
  <div
    class="payment-status-wrapper"
    :class="{ error: isErrorStatus, pending: isPendingStatus }"
  >
    <SignupOverlay>
      <div class="payment-status">
        <Heading level="4" class="">
          {{ statusHeaderTitle }}
        </Heading>
        <Loader v-if="isPendingStatus" />
        <svg-icon
          v-else
          :name="iconName"
          class="payment-status__icon"
        ></svg-icon>
        <Heading
          level="4"
          class="payment-status__title-status"
          :class="{
            pending: isPendingStatus,
            invalid: isErrorStatus,
          }"
        >
          {{ titleStatus }}
        </Heading>
        <div
          v-if="isSuccessStatus || isErrorStatus"
          class="payment-status__number-title"
        >
          {{ orderStatus }}
          <span v-if="isSuccessStatus" class="payment-status__order-number">
            {{ ticket.id }}
          </span>
        </div>
        <div class="payment-status__text">
          {{ statusText }}
          <span v-if="isSuccessStatus" class="payment-status__text-email">
            {{ ticket.email }}
          </span>
        </div>

        <div class="payment-status__btns">
          <ButtonBase
            v-if="isPendingStatus || isErrorStatus"
            color="white"
            size="md-bg"
            class="payment-status__btn"
            @click.native="goToMainPage"
          >
            {{ $t('payment_status.to_main_page') }}
          </ButtonBase>
          <ButtonBase
            color="blue"
            size="md-bg"
            class="payment-status__btn"
            @click.native="handlerPage"
          >
            {{ btnText }}
          </ButtonBase>
        </div>
      </div>
    </SignupOverlay>
  </div>
</template>

<script>
import { getFromLS, removeFromLS } from '@/library/helpers';
import SignupOverlay from '@/components/Signup/SignupOverlay.vue';

import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    SignupOverlay,
    Heading,
    ButtonBase,
    Loader,
  },
  data() {
    return {
      status: 'pending',
      ticket: null,
      timerId: null,
    };
  },
  computed: {
    isPendingStatus() {
      return this.status === 'pending';
    },
    isSuccessStatus() {
      return this.status === 'success';
    },
    isErrorStatus() {
      return this.status === 'error';
    },
    iconName() {
      return this.isErrorStatus ? 'error-pay' : 'success-pay';
    },
    statusHeaderTitle() {
      return this.isPendingStatus || this.isErrorStatus
        ? this.$t('payment_status.title_order')
        : this.$t(`payment_status.title_buy`);
    },
    titleStatus() {
      if (this.isPendingStatus) {
        return this.$t('payment_status.pending_payment');
      }
      if (this.isSuccessStatus) {
        return this.$t('payment_status.success_payment');
      } else {
        return this.$t('payment_status.error_payment');
      }
    },
    orderStatus() {
      return this.isErrorStatus
        ? this.$t('payment_status.bank_error')
        : this.$t(`payment_status.order_payment`);
    },
    statusText() {
      if (this.isPendingStatus) {
        return this.$t('payment_status.pending_text');
      }
      if (this.isSuccessStatus) {
        return this.$t(`payment_status.text_email`);
      } else {
        return this.$t('payment_status.try_again');
      }
    },
    btnText() {
      return this.isPendingStatus || this.isErrorStatus
        ? this.$t('payment_status.btn_try_again')
        : this.$t(`payment_status.to_main_page`);
    },
  },
  created() {
    this.fetchPaymentStatus();
  },
  methods: {
    async fetchPaymentStatus() {
      this.timerId = setInterval(async () => {
        if (!this.isPendingStatus) {
          this.clearTimerId();
        } else {
          const newTicket = {
            order_id: getFromLS('order_id'),
            customs_post: getFromLS('new-ticket').customs_post,
          };

          const { status, ticket } = await this.$store.dispatch(
            'getPaymentStatus',
            newTicket
          );
          this.status = status;
          this.ticket = ticket;
        }
      }, 2000);
    },
    clearTimerId() {
      clearInterval(this.timerId);
    },
    goToMainPage() {
      removeFromLS('new-ticket');
      removeFromLS('order_id');
      this.$router.push({ name: 'MainPage' });
    },
    handlerPage() {
      this.isPendingStatus || this.isErrorStatus
        ? this.$router.push({ name: 'NewTicketPaymentChoice' })
        : this.goToMainPage();
    },
  },
};
</script>

<style lang="sass">
.payment-status-wrapper.error .signup-overlay:before,
.payment-status-wrapper.pending .signup-overlay:before
  @media screen and (max-width: 600px)
    height: calc(100% - 204px)

.payment-status-wrapper
  display: flex
  flex-direction: column
  align-items: center

  .title
    margin-top: 0


.payment-status
  display: flex
  flex-direction: column
  align-items: center
  max-width: 700px

  @media screen and (max-width: 768px)
    max-width: 520px

.payment-status__icon
  width: 60px
  height: 60px

.payment-status__btns
  display: flex
  gap: 32px

  @media screen and (max-width: 600px)
    flex-direction: column

.payment-status__btn
  max-width: 240px

  @media screen and (max-width: 600px)
    width: 100%

.payment-status__title-status.title--4
  text-align: center
  color: var(--primary-color-success)
  margin: 20px 0 30px

  @media screen and (max-width: 600px)
    margin: 16px 0

.payment-status__title-status.title--4.invalid
  color: var(--primary-color-error)

.payment-status__title-status.title--4.pending
  color: var(--primary-color-dark-grey-1)

.payment-status__number-title,
.payment-status__text
  font-size: 24px
  line-height: 32px
  font-weight: 600
  margin-bottom: 24px
  text-align: center

  @media screen and (max-width: 600px)
    font-size: 18px
    line-height: 26px
    margin-bottom: 16px

.payment-status__text
  margin-bottom: 130px

  @media screen and (max-width: 600px)
    margin-bottom: 110px

  @media screen and (max-width: 450px)
    margin-bottom: 72px

.payment-status__order-number,
.payment-status__text-email
  color: var(--btn-color-blue)

.payment-status__btn.primary-btn--medium-big
  width: 420px
  min-width: 290px

  @media screen and (max-width: 600px)
    height: 44px
    font-size: 14px
</style>
